import React from 'react'
import './KuvaOpen.css'


function KuvaOpen(props) {



    return (
        <>
            <img src={props.munKuva} alt='vl-valrak.fi'></img>
        </>
    );
}

export default KuvaOpen;